import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "../../shared/components/forms/Button";
import Input from "../../shared/components/forms/Input";
import { forgotPassword, forgotPasswordSubmit } from "../core/controllers/auth.controller";
import { Dialog } from "../../ui/dialogs/index";
import { getErrorMessage } from '../language/errors';
import CenteredLoader from "../../shared/components/ui/CenteredLoader";

export default function ForgotPassword() {
    const [data, setData] = useState({
        name: '',
        code: '',
        password: '',
        rePassword: '',
        validate: false
    });
    const [loading, setLoading] = useState(false);

    const history = useHistory();

    const onChangeValues = (evt: React.ChangeEvent<HTMLInputElement>) => setData(
        {
            ...data,
            [evt.target.name]: evt.target.value
        }
    );

    const doForgotPassword = async () => {
        setLoading(true);
        const result = await forgotPassword(data.name);

        if (result.error) {

            Dialog.fire({
                icon: 'error',
                title: 'Error',
                text: getErrorMessage(result.error.name, result.error.message),
            });

            return setLoading(false);
        }

        Dialog.fire({
            icon: 'success',
            title: 'Code sent',
            text: 'A verification code has been sent to the email: ' + result.data.Destination,
        })

        setData({
            ...data,
            validate: true
        })
        setLoading(false);
    }

    const doForgotPasswordSubmit = async () => {

        setLoading(true);
        if (data.password !== data.rePassword) {
            setLoading(false);
            return Dialog.fire({
                icon: 'error',
                title: 'Error',
                text: 'Passwords does not match.',
            })
        }

        const result = await forgotPasswordSubmit(data.name, data.code, data.password);

        if (result.error) {
            setLoading(false);
            return Dialog.fire({
                icon: 'error',
                title: 'Error',
                text: getErrorMessage(result.error.name, result.error.message),
            })
            
        }
        Dialog.fire({
            icon: 'success',
            title: 'Password Changed',
            text: 'Your password has been changed.'
        })

        history.push('/');
        setLoading(false);
    }

    return (
        <>
            <Input
                className="mb-2"
                type="text"
                placeholder="Email"
                onChange={ data.validate ? () => {} : onChangeValues }
                value={data.name}
                name="name"
            />
            {
                data.validate ? (
                    <>

                        <Input
                            className="mb-2"
                            type="number"
                            placeholder="Verification Code"
                            onChange={onChangeValues}
                            value={data.code}
                            name="code"
                        />
                        <Input
                            className="mb-2"
                            type="password"
                            placeholder="New Password"
                            onChange={onChangeValues}
                            value={data.password}
                            name="password"
                        />
                        <Input
                            className="mb-2"
                            type="password"
                            placeholder="Repeat Password"
                            onChange={onChangeValues}
                            value={data.rePassword}
                            name="rePassword"
                        />
                    </>) : null
            }

            {
                loading ? <CenteredLoader /> : <Button
                    className="m-auto w-1/2 hover:opacity-70 mt-5 text-xl"
                    onClick={data.validate ? doForgotPasswordSubmit : doForgotPassword}
                >
                    {data.validate ? 'Save password' : 'Send Code'}
                </Button>
            }

            <Link
                className="block m-auto mt-2 text-center hover:opacity-70 underline text-white text-base"
                to="/"
            >
                Do you have an account? Login
            </Link>
        </>
    );
}