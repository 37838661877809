import './sass/Header.scss';
import { BsSearch } from 'react-icons/bs';
import ProfileButton from './ProfileButton';
import { FormEvent, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

export default function Header() {
    const [search, setSearch] = useState('');
    const history = useHistory();
    const searchHandler = (e: FormEvent)=>{
        e.preventDefault();
        history.push(`/search?q=${search}`)
    }
    return (
        <header className="shared-components-ui-header">
            <Link to='/'>
                <div className='header-img'>
                    <img src="/assets/img/logo.svg" alt="Candyish logo" />
                </div>
            </Link>
            <div className='right-side'>
                <div className='search-button'>
                    <form action="" onSubmit={searchHandler}>
                        <input type="search" value={search} onChange={(e)=>setSearch(e.target.value)} required />
                        <button className="fa">
                            <BsSearch />
                        </button>
                    </form>
                </div>
                <div className="profile-button">
                    <ProfileButton />
                </div>
            </div>
        </header>
    );
}