import Loadable from 'react-loadable';
import LoadingScreen from '../screens/LoadingScreen';

export const loadModule = (moduleLoad: () => Promise<any>): any => {
    return Loadable.Map<any, any>({
        loader: {
            ModuleRouter: () => {
                console.log('Loading module...');
                return moduleLoad();
            }
        },
        loading: () => (
            <LoadingScreen />
        ),
        render(loaded, props) {
            console.log('Rendering module');
            const ModuleRouter = loaded.ModuleRouter.default;
            return <ModuleRouter {...props} />
        },
    })
}